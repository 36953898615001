.row {
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  margin-right: $grid-row-margin;
  margin-left: $grid-row-margin;
}

.grid-start {
    justify-content: flex-start;
    text-align: start;
}
.grid-center {
    justify-content: center;
    text-align: center;
}
.grid-end{
    justify-content: flex-end;
    text-align: end;
}
.grid-reverse {
    flex-direction: row-reverse;
}
.grid-top {
    align-items: flex-start;
}
.grid-middle {
    align-items: center;
}
.grid-bottom {
    align-items: flex-end;
}
.grid-around {
    justify-content: space-around;
}
.grid-between {
    justify-content: space-between;
}
.grid-first {
    order: -1;
}
.grid-last {
    order: 1;
}

@media only screen and (min-width: $smPhoneWidth) {
    .col-xs-1 {
        flex-basis: 8.3333333333%;
        max-width: 8.3333333333%;
    }
    .col-xs-2 {
        flex-basis: 16.6666666667%;
        max-width: 16.6666666667%;
    }
    .col-xs-3 {
        flex-basis: 25%;
        max-width: 25%;
    }
    .col-xs-4 {
        flex-basis: 33.3333333333%;
        max-width: 33.3333333333%;
    }
    .col-xs-5 {
        flex-basis: 41.6666666667%;
        max-width: 41.6666666667%;
    }
    .col-xs-6 {
        flex-basis: 50%;
        max-width: 50%;
    }
    .col-xs-7 {
        flex-basis: 58.3333333333%;
        max-width: 58.3333333333%;
    }
    .col-xs-8 {
        flex-basis: 66.6666666667%;
        max-width: 66.6666666667%;
    }
    .col-xs-9 {
        flex-basis: 75%;
        max-width: 75%;
    }
    .col-xs-10 {
        flex-basis: 83.3333333333%;
        max-width: 83.3333333333%;
    }
    .col-xs-11 {
        flex-basis: 91.6666666667%;
        max-width: 91.6666666667%;
    }
    .col-xs-12 {
        flex-basis: 100%;
        max-width: 100%;
    }
}

@media only screen and (min-width: $tabletWidth) {
    .col-sm-1 {
        flex-basis: 8.3333333333%;
        max-width: 8.3333333333%;
    }
    .col-sm-2 {
        flex-basis: 16.6666666667%;
        max-width: 16.6666666667%;
    }
    .col-sm-3 {
        flex-basis: 25%;
        max-width: 25%;
    }
    .col-sm-4 {
        flex-basis: 33.3333333333%;
        max-width: 33.3333333333%;
    }
    .col-sm-5 {
        flex-basis: 41.6666666667%;
        max-width: 41.6666666667%;
    }
    .col-sm-6 {
        flex-basis: 50%;
        max-width: 50%;
    }
    .col-sm-7 {
        flex-basis: 58.3333333333%;
        max-width: 58.3333333333%;
    }
    .col-sm-8 {
        flex-basis: 66.6666666667%;
        max-width: 66.6666666667%;
    }
    .col-sm-9 {
        flex-basis: 75%;
        max-width: 75%;
    }
    .col-sm-10 {
        flex-basis: 83.3333333333%;
        max-width: 83.3333333333%;
    }
    .col-sm-11 {
        flex-basis: 91.6666666667%;
        max-width: 91.6666666667%;
    }
    .col-sm-12 {
        flex-basis: 100%;
        max-width: 100%;
    }
}

@media only screen and (min-width: $desktopWidth) {
    .col-md-1 {
        flex-basis: 8.3333333333%;
        max-width: 8.3333333333%;
    }
    .col-md-2 {
        flex-basis: 16.6666666667%;
        max-width: 16.6666666667%;
    }
    .col-md-3 {
        flex-basis: 25%;
        max-width: 25%;
    }
    .col-md-4 {
        flex-basis: 33.3333333333%;
        max-width: 33.3333333333%;
    }
    .col-md-5 {
        flex-basis: 41.6666666667%;
        max-width: 41.6666666667%;
    }
    .col-md-6 {
        flex-basis: 50%;
        max-width: 50%;
    }
    .col-md-7 {
        flex-basis: 58.3333333333%;
        max-width: 58.3333333333%;
    }
    .col-md-8 {
        flex-basis: 66.6666666667%;
        max-width: 66.6666666667%;
    }
    .col-md-9 {
        flex-basis: 75%;
        max-width: 75%;
    }
    .col-md-10 {
        flex-basis: 83.3333333333%;
        max-width: 83.3333333333%;
    }
    .col-md-11 {
        flex-basis: 91.6666666667%;
        max-width: 91.6666666667%;
    }
    .col-md-12 {
        flex-basis: 100%;
        max-width: 100%;
    }
}

.col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9,
.col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9,
.col-xs-1, .col-xs-10, .col-xs-11, .col-xs-12, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9 {
    flex: 0 0 auto;
    position: relative;
    width: 100%;
    padding-right: $grid-col-padding;
    padding-left: $grid-col-padding;
}