












































svg {
  display: inline-block;
  vertical-align: baseline;
}
