















































































.contact_info {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.contact-form {
  padding: 30px;
  margin: 20px 0;
	font-family: 16px;
	max-width: 420px;
	width: 100%;
}
.contact-form .form {
	display: flex;
	flex-direction: column;
	font-size: 16px;
}
.contact-form input[type="email"],
.contact-form input[type="phone"],
.contact-form input[type="text"],
.contact-form textarea {
	border: solid 1px rgba(255,255,255,1);
	margin-bottom: 15px;
	outline: none;
}
.contact-form textarea {
	resize: none;
}
.contact-form .button {
	background: rgba(255,255,255,.75);
	border: 0;
	color: #000;
	cursor: pointer;
	padding: 10px 50px;
	text-align: center;
  letter-spacing: 2px;
	text-transform: uppercase;
  transition: .5s;
}
.contact-form .button:hover {
	background: rgba(255,255,255,1);
  transition: .5s;
}
.contact-form input::placeholder,
.contact-form textarea::placeholder {
  color: #fff;
}
.contact-form input[type="email"],
.contact-form input[type="phone"],
.contact-form input[type="text"],
.contact-form textarea,
.contact-form .button {
	font-size: 15px;
	border-radius: 0;
}
