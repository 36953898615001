



























































































































.portfolio_page {
  background: #000;
  padding-bottom: 100px;
}

.preload_portfolio_wrap {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 0;
}
.portfolio_main_image {
  opacity: 0;
  animation-name: opacity_portfolio;
  animation-duration: 2s;
  animation-delay: 7s;
  animation-fill-mode: forwards;
  z-index: 1;
}
.portfolio_text {
  position: relative;
  text-align: center;
}
.portfolio_text_box {
  margin: -75px auto 0;
}
.portfolio_title {
  position: relative;
  font-size: 4rem;
  letter-spacing: 20px;
  animation-name: title_portfolio;
  animation-duration: 9s;
  animation-fill-mode: forwards;
  z-index: 1;
}
.portfolio_text .ui-text-medium, 
.portfolio_text .ui-text-regular {
  padding: 0 20%;
}
.port_svg {
  position: absolute;
  opacity: 0;
  transition: .5s;
}
.port_pic_1:hover .port_svg_1, 
.port_pic_2:hover .port_svg_2,
.port_pic_3:hover .port_svg_3,
.port_pic_4:hover .port_svg_4 { 
  opacity: 1;
  transition: .5s;
}

.portfolio_gallery {
  position: relative;
  padding: 10%;
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-auto-rows: auto;
  align-items: center;
  grid-gap: 20px;
}
.port_box_1 { grid-column: 1/5; grid-row: 1/4; }
.port_box_2 { grid-column: 5/8; grid-row: 2/4; }
.port_box_3 { grid-column: 2/5; grid-row: 4/6; }
.port_box_4 { grid-column: 5/9; grid-row: 4/7; }
.port_box_5 { grid-column: 1/2; grid-row: 5/9; }
.port_box_6 { grid-column: 2/5; grid-row: 7/8; }

.port_title {
  transform: rotate(-90deg) translateX(-250px);
  width: 75px;
  height: 50px;
  word-break: unset;
  white-space: nowrap;
}
.port_desc {
  transform: translateX(-20px);
}

@keyframes opacity_portfolio {
	from { opacity: 0; }
	to { opacity: 1; }
}
@keyframes title_portfolio {
	from { 
    opacity: 0; 
    letter-spacing: 80px;
    transform: translateY(-66vh);
  }
  75% {
    opacity: 1;
    letter-spacing: 20px;
    transform: translateY(-66vh);
  }
	to {
    opacity: 1;
    letter-spacing: 20px;
    transform: translateY(0);
  }
}
