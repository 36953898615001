.ui-label {
  padding: $label-padding;
  white-space: nowrap;
  text-transform: uppercase;
  color: $label-color;
}
.ui-label--primary {
  background-color: $primary-color;
}
.ui-label--success {
  background-color: $success-color;
}
.ui-label--danger {
  background-color: $danger-color;
}
.ui-label--warning {
  background-color: $warning-color;
}
.ui-label--light {
  background-color: $light-color;
}
.ui-label--purple {
  background-color: $purple-color;
}
