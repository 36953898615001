





























.listbrands_title {
  margin-bottom: 20px;
  width: 75%;
}
