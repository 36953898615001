.ui-alert {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: $alert-padding;
  margin-bottom: $alert-margin-bottom;
  border-radius: $alert-border-radius;
  opacity: $alert-opacity;
  color: #fff;
  font-size: 18px;
  z-index: 1;
  @media screen and (max-width: $tabletWidth) {
    font-size: 15.12px;
  }
  @media screen and (max-width: $phoneWidth) {
    font-size: 13.68px;
  }
  .button-close {
    z-index: 2;
    width: rem(20);
    height: rem(20);
    &:before, &:after {
      background-color: #fff;
    }
  }
}

.ui-alert--primary {
  background-color: $primary-color;
}
.ui-alert--success {
  background-color: $success-color;
}
.ui-alert--danger {
  background-color: $danger-color;
}
.ui-alert--warning {
  background-color: $warning-color;
}
.ui-alert--light {
  background-color: $light-color;
}
.ui-alert--purple {
  background-color: $purple-color;
}