









































































































































































































































.ui-title-3 {
  letter-spacing: 10px;
}
.slide_item {
  height: 100vh;
}
.slide_image {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.slick-slide .slide_image {
  animation-name: backImage;
  animation-duration: 2s;
  animation-iteration-count: 1;
}
@keyframes backImage {
  0% { transform: scale(1); }
  50% { transform: scale(1); }
  100% { transform: scale(1.1); }
}
.slick-slide.slick-current .slide_image {
  animation-name: zoomImage;
  animation-duration: 10s;
  animation-delay: 0s;
  animation-timing-function: ease;
  animation-iteration-count: 1;
  animation-direction: alternate;
}
@keyframes zoomImage {
  0% { transform: scale(1.1); }
  100% { transform: scale(1); }
}

.container_slide {
  position: relative;
  display: flex;
  justify-content: flex-start;
  padding: 20px 5%;
  align-items: center;
  height: 100vh;
  z-index: 1;
}
.text_slide {
  display: block;
  max-width: 100%;
}
.slide_title, .slide_desc {
  display: block;
  width: 100%;
  text-overflow: clip;
  white-space: nowrap;
  overflow: hidden;
}
.slide_title {
  font-size: 0.8rem;
  line-height: 1rem;
}
.slide_desc {
  font-weight: 400;
  font-size: 1.8rem;
  text-shadow: 0 0 0 #fff;
}
.slick-current .container_slide {
  animation: 10s bgAnimate;
  background: rgba(0,0,0,.25);
  animation-iteration-count: 1;
  animation-timing-function: linear;
  animation-iteration-count: 1;
}
@keyframes bgAnimate {
  0% { background: rgba(0,0,0,.5); }
  70% { background: rgba(0,0,0,.25); }
  100% { background: rgba(0,0,0,.5); }
}
.slick-current .slide_title, .slick-current .slide_desc {
  height: 0;
  animation-iteration-count: 1;
  animation-timing-function: ease;
  animation-direction: alternate;
}
.slick-current .slide_title {
  max-height: 100%;
  animation: 10s titleAnimate;
  animation-delay: 0s;
}
.slick-current .slide_desc {
  max-height: 100%;
  padding-left: 20px;
  animation: 10s descAnimate, 10s titleAnimate;
  animation-delay: 0s;
}
@keyframes titleAnimate {
  0% { height: 0; }
  5% { height: 0; }
  15% { height: 100%; }
  85% { height: 100%; }
  95% { height: 0; }
  100% { height: 0; }
}
@keyframes descAnimate {
  from { transform: translateX(0); text-shadow: 0 0 0 #fff; }
  35% { text-shadow: 0 0 5px #fff, 0 0 10px #fff; }
  50% {
    transform: translateX(30px);
    text-shadow: 0 0 10px #fff, 0 0 15px #fff;
  }
  65% { text-shadow: 0 0 5px #fff, 0 0 10px #fff; }
  to { transform: translateX(0); text-shadow: 0 0 0 #fff; }
}

/* activity */
.home_activity {
  position: relative;
  display: block;
}
.activity_section {
  width: 100%;
  padding: 2% 10%;
  margin: 40px 0;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.activity_desc { width: 40%; margin-left: 5%; }
.activity_section:nth-child(even) .activity_image { order: 2; }
.activity_section:nth-child(even) .activity_desc { order: 1; margin-right: 5%; }

.hg_desc .ui-text-regular {
  transform: translateX(50px);
  opacity: 0;
  transition: 3s ease;
}
.elem_toggle .hg_desc .ui-text-regular {
  transform: translateX(0);
  opacity: 1;
  transition: 3s ease;
}
.activity_image {
  position: relative;
  display: inline-block;
  width: 40%;
  margin: 0;
  transition: .5s;
}
.activity_pic {
  height: 100vh;
  z-index: 0;
}

.activity_image.elem_toggle {
  animation-name: imageMoving;
  animation-iteration-count: 1;
  animation-delay: .1s;
  animation-duration: 5s;
  animation-timing-function: ease;
}
.elem_toggle .activity_pic {
  animation-name: imageWidthGrow;
  animation-iteration-count: 1;
  animation-duration: 3s;
  animation-timing-function: cubic-bezier(0.18, 0.89, 0.29, 1);
}
@keyframes imageMoving {
  0% { transform: translateY(50px); }
  100% { transform: translateY(0); }
}
@keyframes imageWidthGrow {
  0% { 
    height: 100vh; 
    max-width: 0;
    opacity: 0;
  }
  100% {
    height: 100vh;
    max-width: 100%;
    opacity: 1;
  }
}

/* categories */
.home_categories {
  position: relative;
  display: flex;
  justify-content: center;
}
