@function rem($size) {
    @return ($size/18) + rem;
}

@function calcFluidFontSize($f-min, $f-max, $v-min, $v-max, $units: px) {
    $k: ($f-max - $f-min)/($v-max - $v-min);
    $b: $f-min - $k * $v-min;
    $b: $b + $units;
    @return calc( #{$k} * 100vw + #{$b} );
}

// @function rem($pixels, $context: $mainFontSize) {
//     @if (unitless($pixels)) {
//         $pixels: $pixels * 1px;
//     }
//     @if (unitless($context)) {
//         $context: $context * 1px;
//     }
//     @return $pixels / $context * 1rem;
// }
// @mixin size($width, $height: $width) {
//     width: $width;
//     height: $height;
// }
// @mixin placeholder {
//     ::-webkit-input-placeholder {@content};
//     :-moz-placeholder {@content};
//     ::-moz-placeholder {@content};
//     :-ms-input-placeholder {@content};
// }