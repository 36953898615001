.button,
button {
	display: inline-block;
	padding: $button-padding;
	color: $button-color;
	text-align: center;
	cursor: pointer;
	font-size: $button-font-size;
	//! Reset
	white-space: nowrap;
	user-select: none;
	border: none;
	// Common
	letter-spacing: normal;
	word-spacing: normal;
	vertical-align: top;
	text-rendering: auto;
	@media screen and (max-width: $tabletWidth) {
		font-size: calc(#{$button-font-size} - 16%);
	}
	@media screen and (max-width: $phoneWidth){
		font-size: calc(#{$button-font-size} - 24%);
	}
	&:hover{
		opacity: .8;
		outline: 0;
	}
	// BIG
	&.button--big {
		$localFontSize: 22px;
		font-size: $localFontSize;
		@media screen and (max-width: $tabletWidth) {
			font-size: calc(#{$localFontSize} - 26%);
		}
		@media screen and (max-width: $phoneWidth){
			font-size: calc(#{$localFontSize} - 34%);
		}
	}

	//* LARGE
	&.button--large {
		$localFontSize: 26px;
		font-size: $localFontSize;
		@media screen and (max-width: $tabletWidth) {
			font-size: calc(#{$localFontSize} - 28%);
		}
		@media screen and (max-width: $phoneWidth){
			font-size: calc(#{$localFontSize} - 36%);
		}
	}

	//* PLAIN
	&.button--plain {
		background-color: transparent;
		&:hover {
			color: #fff;
		}
	}

	//* ROUND
	&.button--round {
		border-radius: $button--round-border-radius;
		padding: .48em 1.2em;
	}

	//* DISABLE
	&.button--disable
	&[disable] {
		opacity: .6;
		cursor: not-allowed;
		pointer-events: none;
		background-image: none;
	}
}

//! Button style
//* Default
.button-default {
	color: $linkColor;
	border: 1px solid $border-base;
	background-color: $default-color;
	&.button--plain {
		color: $linkColor;
		&:hover {
			background-color: $linkColor;
		}
	}
}
//* Primary
.button-primary {
	background-color: transparent;
	border: 1px solid rgba(255,255,255,.2);
	&.button--plain {
		color: $primary-color;
		border: 1px solid $primary-color;
		&:hover {
			background-color: $primary-color;
		}
	}
}
//* Success
.button-success {
	background-color: $success-color;
	&.button--plain {
		color: $success-color;
		border: 1px solid $success-color;
		&:hover {
			background-color: $success-color;
		}
	}
}
//* Danger
.button-danger {
	background-color: $danger-color;
	&.button--plain {
		color: $danger-color;
		border: 1px solid $danger-color;
		&:hover {
			background-color: $danger-color;
		}
	}
}
//* Warning
.button-warning {
	background-color: $warning-color;
	&.button--plain {
		color: $warning-color;
		border: 1px solid $warning-color;
		&:hover{
			background-color: $warning-color;
		}
	}
}
//* Light
.button-light {
	background-color: $light-color;
	&.button--plain{
		color: $light-color;
		border: 1px solid $light-color;
		&:hover {
			background-color: $light-color;
		}
	}
}

//! Another buttons:
//* Close
.button-close {
	$localWidth: 30px;
	display: inline-block;
	position: relative;
	width: $localWidth;
	height: $localWidth;
	overflow: hidden;
	cursor: pointer;
	opacity: .7;
	transition: $mainTransition;
	&:hover {
		opacity: 1;
	}
	&:before, &:after {
		content: '';
		position: absolute;
		height: 1px;
		width: 100%;
		top: 50%;
		left: 0;
		margin-top: -1px;
		background: #000;
	}
	&:before {
		transform: rotate(45deg);
	}
	&:after {
		transform: rotate(-45deg);
	}
}

//* Burget
.button-burger {
	position: relative;
	width: 34px;
	height: 16px;
	transform: rotate(0deg);
	transition: .5s ease-in-out;
	cursor: pointer;
	&:hover,
	&:focus {
		.line-1 {
			transform: translateY(-1px);
		}
		.line-3 {
			transform: translateY(1px);
		}
	}
	&.active {
		.line-1 {
			transform: translateY(8.33px) translateX(0) rotate(45deg);
		}
		.line-2 {
			opacity: 0;
		}
		.line-3 {
			transform: translateY(-8.33px) translateX(0) rotate(-45deg);
		}
	}

	// Burger Line
	.line {
		display: block;
		position: absolute;
		height: 2px;
		width: 100%;
		background-color: #333;
		border-radius: 4px;
		opacity: 1;
		left: 0;
		transform: rotate(0deg);
		transition: $mainTransition;
		&.line--white {
			background-color: #fff;
		}
	}
	// Center
	.line-1 {
		top: 0%;
	}
	.line-2 {
		top: 50%;
	}
	.line-3 {
		top: 100%;
	}
}