// Colors
@import 'colors';

// Font
$titleFont: 'Konkord-Retro';
$baseFont: Arial, sans-serif;

// Size
$mainFontSize: 24px;
$mainLineHeight: 1.4;

$desktopWidth: 100%;
$smDesktopWidth: 980px;
$tabletWidth: 768px;
$phoneWidth: 480px;
$smPhoneWidth: 320px;

// Animation
$mainTransition: all .25s cubic-bezier(.02,.01,.47,1);

// Buttons
$button-padding: .4em 1em;
$button-color: #fff;
$button-font-size: 18px;
  // Round
$button--round-border-radius: 1.2em;


// Alert
$alert-padding: .42em .4em;
$alert-margin-bottom: 1em;
$alert-border-radius: .6em;
$alert-opacity: .7;

// Tag
$tag-padding: .4em 1em;
$tag-border-radius: .9em;
$tag-opacity: .7;

// Card
$card-border-radius: .6em;
$card-padding: rem(30);
$card-background-color: #fff;

// Form Elements
$form-el-width: 100%;
$form-el-height: rem(40);
$form-el-padding: rem(10) rem(14);
$form-el-border-radius: .6em;
  // Checkbox
$form-el-checkbox-size: 1rem;
$form-el-checkbox-color: $primary-color;

// Label
$label-padding: rem(6) rem(18);
$label-color: #fff;

// Message
$message-border-radius: .6em;
$message-padding: rem(10) rem(14);

// Message BOX
$messageBox-wrapper-background-color: rgba(39, 39, 39, 0.48);
$messageBox-background-color: #fff;
$messageBox-border-radius: rem(8);
$messageBox-title-font-size: rem(24);

// Table
$table-background-color: #fff;
$table-line-height: 1.5em;
$table-td-padding: 14px;

// Sidebar
$sidebar-height: 96vh;
$sidebar-width: 240px;
$sidebar-content-padding: 40px 0;
$sidebar-content-padding--mobile: 20px 30px;
$sidebar-list-margin: rem(20) auto;
$sidebar-item-font-size: rem(18);
$sidebar-item-line-height: 2.1;

// Navbar
$navbar-background-color: #000;
$navbar-height: 80px;
$navbar-item-color: #fff;
$navbar-item-hover-color: #eee;
$navbar-item-hover-background-color: transparent;
$navbar-fixed-z: 3;

// Grid
$grid-row-margin: rem(-10);
$grid-col-padding: rem(10);