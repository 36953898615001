











































































































.logo_for_morph {
    display: none;
    fill: #fff;
}
.morph_path_0 {
	visibility: visible!important;
}
.logo_brands {
    display: inline-block;
    max-width: 100%;
    height: auto;
    padding: 0;
    cursor: pointer;
    transform: scale(1);
    opacity: .25;
    transition: 1.5s;
    padding: 10px;
}
.logo_brands:hover {
    transform: scale(2);
    opacity: 1;
    transition: .5s;
}
.container_logo_brands {
    display: grid;
    grid-template-columns: repeat(18, 1fr);
    grid-auto-rows: minmax(55px, 55px);
    grid-gap: 10px;
    position: relative;
    text-align: center;
    overflow: hidden;
    padding: 7% 1%;
    margin: 0;
}
.logo_morphing {
    position: relative;
    display: block;
    margin: 0 auto;
    width: 100%;
    height: auto;
}
.box_collection { 
    padding: 40px;
    grid-column: 7 / 13;
    grid-row: 1 / 12;
}
.logo_box {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
