
















.hg_title {
  position: relative;
  display: block;
  margin-bottom: 30px;
  margin-left: 70px;
}
.hg_title .ui-title-1 {
  position: relative;
  color: #666;
  letter-spacing: 0;
  line-height: 200%;
  transition: 3s ease;
  z-index: 1;
}
.elem_toggle .ui-title-1 {
  color: #fff;
  letter-spacing: 7px;
  transition: 3s ease;
}
.hg_desc .ui-text-regular {
  margin-left: 70px;
  letter-spacing: 2px;
}
.hg_element {
  position: absolute;
  bottom: -30px;
  left: -70px;
  width: 300px;
}
.hg_elem {
  fill:none;
  stroke:#666;
  stroke-width:1;
  stroke-opacity:1;
  stroke-linecap:round
}
.elem_toggle .hg_elem {
  stroke-dasharray: 300;
  stroke-dashoffset: 300;
  animation-name: dash;
  animation-timing-function: ease;
  animation-iteration-count: 1;
  animation-delay: .1s;
  animation-duration: 5s;
  animation-fill-mode: forwards;
  animation-play-state: running;
}
@keyframes dash {
  from { stroke-dashoffset: 300; stroke:#333; }
  to { stroke-dashoffset: 0; stroke:#999; }
}
