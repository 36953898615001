



























.locale_changer {
    position: relative;
    display: flex;
    align-items: center;
    padding: 0 10px;
    margin-right: 20px;
    z-index: 4;
}
.lang_item {
    cursor: pointer;
    padding: 10px;
    font-size: .5rem;
    letter-spacing: 3px;
    text-transform: uppercase;
    font-family: 'Konkord-Retro';
}
