.ui-messageBox__wrapper {
  display: none;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2002;
  background-color: $messageBox-wrapper-background-color;
}
.ui-messageBox {
  $localMinWidth: 400px;
  position: relative;
  min-width: $localMinWidth;
  padding: rem(12) rem(14);
  background-color: $messageBox-background-color;
  border: 1px solid $border-base;
  border-radius: $messageBox-border-radius;
  z-index: 2000;
  overflow: hidden;
  @media screen and (max-width: $tabletWidth) {
    min-width: calc(#{$localMinWidth} - 16%);
  }
  @media screen and (max-width: $phoneWidth) {
    min-width: calc(#{$localMinWidth} - 40%);
  }
  .button-close {
    width: rem(22);
    height: rem(22);
  }
}
.ui-messageBox__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: rem(8);
  margin-bottom: rem(8);
}

.ui-messageBox__content {
  margin-bottom: rem(16);
}
.ui-messageBox__footer{
  text-align: right;
}
.messageBox-title {
  font-size: $messageBox-title-font-size;
}