

























.list_brands {
  display: block;
}
