





























































































































































































































































































































































































































































































.petrachers1{fill:#5C4330;}
.petrachers2{fill:#65523D;}
.petrachers3{fill:#6B5641;}
.petrachers4{fill:#755C49;}
.petrachers5{fill:#664E3B;}
.petrachers6{fill:#7D6551;}
.petrachers7{fill:#6E503F;}
.petrachers8{fill:#69503D;}
.petrachers9{fill:#6A543D;}
.petrachers10{fill:#20140B;}
.petrachers11{fill:#624533;}
.petrachers12{fill:#6C4D3C;}
.petrachers13{fill:#6D5442;}
.petrachers14{fill:#CDC2AD;}
.petrachers15{fill:#7C604E;}
