// Base color
$linkColor: #d0d0ff;
$mainFontColor: #ffffff;

// Main color
$default-color: #202020;
$primary-color: #444ce0;
$success-color: #26de81;
$danger-color: #fc5c65;
$warning-color: #fed330;
$light-color: #999999;
$purple-color: #8854d0;

// Neutral Color
$neutral-primary: #303133;
$neutral-regular: #666666;
$neutral-secondary: #999999;
$neutral-placeholder: #C0C4CC;

// Border Color
$border-base: #DCDFE6;
$border-light: #E4E7ED;
$border-lighter: #EBEEF5;
$border-lightex: #F2F6FC;

// Backgrounds
.bg-primary {
    background-color: $primary-color;
}
.bg-success {
	background-color: $success-color;
}
.bg-danger {
	background-color: $danger-color;
}
.bg-warning {
	background-color: $warning-color;
}
.bg-light {
	background-color: $light-color;
}
.bg-purple {
	background-color: $purple-color;
}

.bg-neutral-primary {
	background-color: $neutral-primary;
}
.bg-neutral-regular {
	background-color: $neutral-regular;
}
.bg-neutral-secondary {
	background-color: $neutral-secondary;
}
.bg-neutral-placeholder {
	background-color: $neutral-placeholder;
}

.bg-border-base {
	background-color: $border-base;
}
.bg-border-light {
	background-color: $border-light;
}
.bg-border-lighter {
	background-color: $border-lighter;
}
.bg-border-lightex {
    background-color: $border-lightex;
}