.ui-card {
  background-color: $card-background-color;
  padding: $card-padding;
  color: $neutral-primary;
  border: 1px solid $border-light;
  border-radius: $card-border-radius;
  &.ui-card--shadow-always {
    box-shadow: 0 2px 12px 0 rgba(0,0,0,.1);
  }
  &.ui-card--shadow {
    transition: $mainTransition;
    &:hover {
      box-shadow: 0 2px 12px 0 rgba(0,0,0,.1);
    }
  }
}
