






































































































.contact_page {
  position: relative;
  display: block;
}
.contact_image {
  position: relative;
}
.feedback_title {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
  width: 40%;
  height: 100vh;
  padding: 0 5%;
  background: rgba(0,0,0,.1);
}
.feedback_title h1, .feedback_title h2 { color: #fff; }
.feedback_form {
  position: absolute;
  display: flex;
  padding: 0 5%;
  box-sizing: border-box;
  justify-content: flex-end;
  align-items: center;
  min-height: 100vh;
  width: 100%;
}

.contact_address {
  padding: 50px 0;
  background: #000000;
  background: linear-gradient(to top, rgba(0,0,0,0) 0%, #000000 100%);
}
.contact_title {
  text-transform: uppercase;
  font-family: konkord-retro;
  text-align: center;
  font-size: 3rem;
  padding: 10vh 0;
  letter-spacing: 3px;
  color: #fff;
}
.showrooms {
  position: relative;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 10vh;
}
.showroom {
  text-align: center;
}
.showroom_image {
  padding: 20px; 
}
.showroom_pre {
  text-transform: uppercase;
  margin-bottom: 20px;
}
.showroom_title {
  margin-bottom: 5px;
  font-size: 1.2rem;
  font-family: konkord-retro;
  color: #fff;
}
.showroom_address a, .showroom_phone a {
  color: #666;
}
.showroom_address {
  margin-bottom: 5px;
}
.showroom_phone a {
  display: block;
}
.social_icons {
  justify-content: center;
  margin: 0;
  margin-bottom: 10px;
}

