html, body, p, ol, ul, li, dl, dt, dd, blockquote, figure, fieldset, legend, textarea, pre, iframe, hr,
code, pre, h1, h2, h3, h4, h5, h6 {
    margin: 0;
    padding: 0;
}

h1, h2, h3, h4, h5, h6 {
    font-size: 100%;
    font-weight: normal;
}
ul, li {
    list-style-type: none;
}
button, input,
select, textarea {
    margin: 0;
}

html {
    box-sizing: border-box;
}

img,
audio,
video {
    height: auto;
    max-width: 100%;
}
audio,
canvas,
iframe,
img,
svg,
video {
    vertical-align: middle;
}

textarea {
    resize: none;
}

input,
select,
button {
    outline: none;
}

*, *:before, *:after{
    box-sizing: border-box;
}

iframe {
    border: 0;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}
td, th {
    padding: 0;
    text-align: left;
}