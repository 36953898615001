
















.hg { 
	fill: none; 
	stroke:rgba(255,255,255,.75); 
	stroke-width: 4.5; 
	stroke-linecap: round; 
	stroke-linejoin: round; 
	stroke-miterlimit: 10;
}
