






























.social_icons {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-right: 20px;
    z-index: 4;
    a {
        display: inline-block;
        line-height: 0rem;
        svg {
            width: 15px;
            margin: 0 7px;
            fill: rgba(255,255,255,.75);
            box-shadow: 0 0 30px #000;
            transform: scale(1);
        }
        &:hover svg {
            fill: rgba(255,255,255,1);
            transform: scale(1.5);
            transition: .3s;
        }
    }
}
