.ui-message {
  display: none;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: rem(20);
  right: 10%;
  padding: $message-padding;
  background-color: #fff;
  border: 1px solid $border-lighter;
  border-radius: $message-border-radius;
  z-index: 2000;
  overflow: hidden;

  &.ui-message--primary {
    color: #fff;
    background-color: $primary-color;
  }
  &.ui-message--success {
    color: #fff;
    background-color: $success-color;
  }
  &.ui-message--warning {
    color: #fff;
    background-color: $warning-color;
  }
  &.ui-message--danger {
    color: #fff;
    background-color: $danger-color;
  }
}

.ui-block-info {
  padding: $message-padding;
  .ui-info {
    &.ui-info--primary {
      color: $primary-color;
    }
    &.ui-info--success {
      color: $success-color;
    }
    &.ui-info--warning {
      color: $warning-color;
    }
    &.ui-info--danger {
      color: $danger-color;
    }
  }
}