





















































































































































































.arredo0{fill:#BC9951;}
.arredo1{fill:#B99955;}
.arredo2{fill:#0C994A;}
.arredo3{fill:#EC2326;}
