//Move to ui
.sidebar {
  width: $sidebar-width;
  height: $sidebar-height;
  position: fixed;
  // height: 100%;
  top: 0;
  bottom: 0;
  margin-top: 80px;
  overflow-x: scroll;
  transition: $mainTransition;
  z-index: 10;

  @media screen and (max-width: $phoneWidth) {
    left: -240px;
  }

  &.full {
    width: 100%;
    background-color: #fff;
  }
}

.sidebar-open-button {
  display: none;
  position: fixed;
  top: 90px;
  left: 10px;
  z-index: 20;

  @media screen and (max-width: $phoneWidth) {
    display: block;
  }
}

.sidebar-content {
  padding: $sidebar-content-padding;
  color: $neutral-regular;
  transition: $mainTransition;

  @media screen and (max-width: $phoneWidth) {
    padding: $sidebar-content-padding--mobile;
  }
}

.sidebar-list {
  display: flex;
  flex-direction: column;
  margin: $sidebar-list-margin;
}

.sidebar-item {
  position: relative;
  line-height: $sidebar-item-line-height;
  transition: $mainTransition;
}

.sidebar-item,
.sidebar-link {
  font-size: $sidebar-item-font-size;
  color: $neutral-regular;
  &:hover {
    color: $neutral-primary;
  }
}

.category_title {
  position: fixed;
  width: 100px;
  height: 100vh;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}
.link_to_back {
  width: 100%;
  height: 100%;
  padding: 5% 10px;
  display: flex;
  place-items: center;
  background: rgba(0,0,0,.5);
  transition: 1s;
}
.link_to_back:hover {
  background: rgba(0,0,0,.75);
  transition: 1s;
}