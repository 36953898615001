.ui-tag__wrapper {
  display: inline-block;
}
.ui-tag {
  $localFontSize: 18px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: $tag-padding;
  border: 1px solid $border-base;
  border-radius: $tag-border-radius;
  // opacity: $tag-opacity;
  color: $neutral-regular;
  font-size: $localFontSize;
  z-index: 1;
  
  @media screen and (max-width: $tabletWidth) {
    font-size: calc(#{$localFontSize} - 16%);
  }
  @media screen and (max-width: $phoneWidth) {
    font-size: calc(#{$localFontSize} - 24%);
  }

  .button-close {
    margin-left: 8px;
    z-index: 2;
    width: rem(14);
    height: rem(14);
  }
}

