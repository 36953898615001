







































































































































































































































































































































































































































































































































































































































































































































































































































































































































.portfolio_main_svg {
  fill: none;
  z-index: 1;
}
.portfolio_main_lines {
    stroke: rgba(255,255,255,1);
    stroke-width: 1;
    stroke-miterlimit: 10;
    stroke-dasharray: 1000;
    stroke-dashoffset: 1000;
    animation-name: draw_portfolio;
    animation-duration: 7s;
    animation-fill-mode: forwards;
    animation-play-state: running;
    animation-timing-function: ease;
    animation-iteration-count: 1;
}
@keyframes draw_portfolio {
    from { 
        stroke: rgba(255,255,255,0.25);
        stroke-dashoffset: 1000;
    }
    to { 
        stroke: rgba(255,255,255,1);
        stroke-dashoffset: 0;
    }
}
