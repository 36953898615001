











































































































    .progress0{fill:#009680;}
	.progress1{fill:#E11580;}
	.progress2{fill:#8F837E;}
	.progress3{fill:#DFCCA4;}
	.progress4{fill:#9B4900;}
	.progress5{fill:#444341;}
	.progress6{fill:#22307F;}
	.progress7{fill:#FDC800;}
	.progress8{fill:#F18A00;}
	.progress9{fill:#E4260E;}
	.progress10{fill:#A71280;}
	.progress11{fill:#B2B3B5;}
	.progress12{fill:#000100;}
	.progress13{fill:#40434C;}
	.progress14{fill:#0AA9E2;}
