




































































































































/* hg_animate */
.hg_bg {
  max-width: 100%;
}
.hg_bg .hg { 
	fill: none;
  stroke: rgba(255,255,255,.07);
  stroke-width: 2;
	animation-fill-mode: forwards;
  animation-play-state: running;
  animation-timing-function: ease;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}
.hg_bg .hg_h {
	stroke-dasharray: 300;
	stroke-dashoffset: 300;
	animation-name: hg_h;
	animation-duration: 10s;
}
.hg_bg .hg_h1 { animation-delay: 0s; }
.hg_bg .hg_h2 { animation-delay: .5s; }
.hg_bg .hg_h3 { animation-delay: 1s; }
@keyframes hg_h {
	from { stroke-dashoffset: 300; }
	to { stroke-dashoffset: 0; }
}

.hg_bg .hg_g {
	stroke-dasharray: 500;
	stroke-dashoffset: 500;
	animation-name: hg_g;
	animation-delay: 1s;
	animation-duration: 9s;
}
@keyframes hg_g {
	from { stroke-dashoffset: 500; }
	to { stroke-dashoffset: 0; }
}

.hg_bg .hg_circle {
	stroke-dasharray: 1200;
	stroke-dashoffset: 1200;
	animation-name: hg_circle;
	animation-delay: 2s;
  animation-duration: 8s;
}
@keyframes hg_circle {
	from { stroke-dashoffset: 1200; }
	to { stroke-dashoffset: 0; }
}

.about_page { padding: 0; }
.about_section {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.phil_box {
  width: 40%;
}
.about_text {
  width: 40%;
  padding: 10% 40px;
  transform: translateX(20%);
  text-align: left;
  z-index: 1;
}
.about_image {
  width: 60%;
  transform: translateX(-10%);
}
.about_title {
  letter-spacing: 20px!important;
  text-shadow: 0 0 30px #fff;
}

/* philosophy */
.philosophy_section {
  display: flex;
  justify-content: center;
  padding: 20px 5%;
}
.about_box {
  width: 50%;
}
.phil_box {
  margin-right: 20px;
}
.phil_image {
  position: relative;
  display: block;
}
.phil_pic, .team_pic {
  max-height: 100vh;
  transform: scale(0.95);
  transition: 1s;
}
.phil_pic:hover, .team_pic:hover { 
  transform: scale(1);
  transition: 3s;
}
.phil_svg, .team_svg {
  position: absolute;
  opacity: 0;
  transition: .5s;
}
.phil_title {
  position: relative;
  font-size: 2.6rem;
  letter-spacing: 10px;
  z-index: 2;
}
.phil_desc {
  margin-bottom: 40px;
}

/* team */
.team_section {
  position: relative;
  padding: 20px 5%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: minmax(150px, auto);
  align-items: flex-start;
  grid-gap: 20px;
}
.team_extra_section {
  padding: 20px 20%;
}
.team_box_2 {
  align-self: flex-end;
}
.team_pic_3 {
  max-width: 66%;
  transition: .5s;
}
.team_text {
  text-align: right;
  padding: 40px 0;
}
.team_title {
  position: relative;
  font-size: 2.6rem;
  letter-spacing: 10px;
  z-index: 2;
}
