.ui-table {
  width: 100%;;
  border-collapse: collapse;
  background-color: $table-background-color;
  line-height: $table-line-height;
  transition: $mainTransition;

  td {
    padding: $table-td-padding;
    border-bottom: 1px solid $border-lighter;

    &:first-child {
      padding-right: 20px;
    }
  }

  thead {
    th {
      padding: 14px;
      overflow: hidden;
      white-space: nowrap;
      user-select: none;
      border-bottom: 1px solid $border-light;
    }
    &:first-child {
      padding-left: 20px;
    }
  }

  &.ui-table--hover {
    tbody {
      tr:hover {
        background-color: $border-lightex;
      }
    }
  }
}




