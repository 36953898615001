




























































































































































































.flowmap {
    position: relative;
}
.flowmap canvas {
    position: absolute;
    display: inline-block;
    top: 0px;
    left: 0px;
}
