



































.estel0{fill:#216147;}
.estel1{fill:#65297C;}
.estel2{fill:#E70A76;}
.estel3{fill:#2FA549;}
.estel4{fill:#678DC6;}
.estel5{fill:#F180A4;}
