









































.mathiosstone0{fill:#EC1D25;}
