














































.hermitage_logo {
	fill: none;
	stroke: #fff;
	stroke-width: 1.4;
	stroke-linecap: round;
	stroke-linejoin: round;
	stroke-miterlimit: 10;
}
.home_int {
	fill: #fff;
}
