























































































.barausse0{fill:#0379B7;}
.barausse1{fill:#40444F;}
.barausse2{fill:#888B91;}
